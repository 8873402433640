import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

const NotFoundPage = () => (
  <>
  <Header />
  <Layout>
    <SEO title="404: ページが見つかりません" />
    <h1>ページが見つかりません</h1>
    <p>お探しのページは、移動または削除された可能性があります。</p>
    <Link to="/">トップページへ戻る</Link>
  </Layout>
  </>
)

export default NotFoundPage
